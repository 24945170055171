<template>
   <v-layout align-start>
      <v-flex
         xs12
         :style="$vuetify.breakpoint.smAndUp ? 'margin: 0px 15%' : ''"
      >
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR BODEGA -->
         <v-dialog v-model="dialogTipoBeca" persistent max-width="600px">
            <v-form ref="formTipoBecas" v-model="validTipoBeca" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="nombre"
                                 :rules="carnetRules"
                                 color="primary"
                                 label="Nombre"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="descripcion"
                                 :rules="periodoLectivoRules"
                                 color="primary"
                                 label="Descripcion"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="cuotaInicial"
                                 color="primary"
                                 label="Cuota Inicial"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="descuento"
                                 color="primary"
                                 label="Descuento"
                              ></v-text-field>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validTipoBeca"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR BODEGA -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover tipo beca</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover el Tipo
                                 Beca?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       Nombre:
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ nombre }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       Cuota Inicial:
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ cuotaInicial }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       Descuento:
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ descuento }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerTipoBecas"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Tipo de Becas</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        v-on="on"
                        @click="nuevoTipoBeca()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px"> Agregar Tipo Beca </span>
               </v-tooltip>
            </v-toolbar>

            <!-- DATATABLE tipoBecas -->
            <v-data-table
               :headers="headers"
               :items="tipoBecas"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Eliminar </span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="tipoBecas.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'

export default {
   name: 'TipoBecas',

   data: () => ({
      // Variables
      dialogTipoBeca: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Nombre Beca', value: 'nombre', class: 'titulo' },
         { text: 'Descripcion', value: 'descripcion', class: 'titulo' },
         { text: 'Cuota Inicial', value: 'cuotaInicial', class: 'titulo' },
         { text: 'Descuento', value: 'descuento', class: 'titulo' },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      tipoBecas: [],

      validTipoBeca: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades bodega
      tipoBecaId: '',
      nombre: '',
      descripcion: '',
      cuotaInicial: '',
      descuento: '',

      // Reglas de validacion de formularios
      carnetRules: [(v) => !!v || 'Ingrese una nombre'],
      periodoLectivoRules: [(v) => !!v || 'Ingrese una descripcion']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nuevo tipo de beca'
            : 'Editar tipo de beca'
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(5, 'ver')) {
         this.listarTipoBecas()
         this.listarComboBoxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarTipoBecas() {
         // Obteniendo tipoBecas
         axios
            .get('api/TipoBeca')
            .then((response) => {
               this.tipoBecas = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las tipoBecas en el datatable se puede resetear
      reset() {
         this.listarTipoBecas()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/TipoBeca/' + item.tipoBecaId)
            .then((response) => {
               this.result = response.data
               this.tipoBecaId = this.result.tipoBecaId
               this.nombre = this.result.nombre
               this.descripcion = this.result.descripcion
               this.cuotaInicial = this.result.cuotaInicial
               this.descuento = this.result.descuento
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogTipoBeca = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.nombre = item.nombre
         this.descripcion = item.descripcion
         this.tipoBecaId = item.tipoBecaId
         this.descuento = item.descuento
         this.cuotaInicial = item.cuotaInicial

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogTipoBeca = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formTipoBecas.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/TipoBeca/' + this.tipoBecaId, {
                     descripcion: this.descripcion,
                     nombre: this.nombre,
                     cuotaInicial: this.cuotaInicial,
                     descuento: this.descuento
                  })
                  .then(() => {
                     this.listarTipoBecas()
                     this.close()
                     this.snackText = 'Bodega editado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/TipoBeca', {
                     descripcion: this.descripcion,
                     nombre: this.nombre,
                     cuotaInicial: this.cuotaInicial,
                     descuento: this.descuento
                  })
                  .then(() => {
                     this.listarTipoBecas()
                     this.close()
                     this.snackText = 'Bodega agregado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formTipoBecas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombre = ''
         this.descripcion = ''
         this.cuotaInicial = ''
         this.descuento = ''
      },
      nuevoTipoBeca() {
         this.dialogTipoBeca = true
      },
      removerTipoBecas() {
         // Enviando metodo delete
         axios
            .delete('api/TipoBeca/' + this.tipoBecaId)
            .then(() => {
               this.listarTipoBecas()
               this.close()
               this.snackText = 'Beca removida exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboBoxes() {
         // Obteniendo sub categorias
         axios
            .get('api/TipoBeca')
            .then((response) => {
               this.cmbTipoBeca = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
